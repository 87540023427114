interface ChatHeaderProps {
  children: React.ReactNode
}

// export const ChatHeader = ({ children }: ChatHeaderProps) => {
//   return (
//     <div className="flex w-full items-center justify-between bg-gradient-to-r from-primary-start to-primary-stop p-m">
//       {children}
//     </div>
//   )
// }
export const ChatHeader = ({ children }: ChatHeaderProps) => {
  return (
    <div className="flex w-full items-center justify-between bg-surface-accent p-m">
      {children}
    </div>
  )
}
