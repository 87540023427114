import type { Customisations } from '@lorikeetai/web-sdk/types'
import { ButtonIcon, cn, TooltipProvider } from '@optechai/design-system'
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { MessageCirclePlusIcon, XIcon } from 'lucide-react'

import { CustomisationContext } from '@web/components/message/messages'

import '@optechai/design-system/styles/global.css'
import '@optechai/design-system/styles/fonts/roboto/roboto.css'

import { ChatHeader } from './components/chat-header'
import { generateCustomTheme } from './generate-custom-theme'

export const loader = async (
  args: LoaderFunctionArgs,
): Promise<{
  customisations: Customisations
}> => {
  const urlData = new URL(args.request.url)
  const customisations = urlData.searchParams.get('customisations')

  if (customisations) {
    const fromBase64 = Buffer.from(customisations, 'base64').toString('utf-8')
    const asJSON = JSON.parse(fromBase64)

    return {
      customisations: asJSON,
    }
  }

  return {
    customisations: {
      title: 'Lorikeet AI Agent',
    },
  }
}

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    type: 'image/x-icon',
    href: import.meta.env.PROD
      ? 'https://assets.lorikeetcx.ai/chat/favicon.ico'
      : '/favicon.ico',
  },
]
export const meta: MetaFunction = () => [
  { name: 'og:type', content: 'website' },
  { name: 'og:title', content: 'Lorikeet' },
  { name: 'robots', content: 'noindex, nofollow' },
  {
    name: 'og:description',
    content: 'AI-backed solution for managing, creating and editing workflows.',
  },
  { name: 'og:url', content: 'https://chat.lorikeetcx.ai' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  {
    property: 'og:image',
    content: import.meta.env.PROD
      ? 'https://assets.lorikeetcx.ai/chat/og-image.png'
      : '/og-image.png',
  },
]

function App() {
  const { customisations } = useLoaderData<typeof loader>()
  const theme = generateCustomTheme(customisations.theme)

  return (
    <html className="rebrand h-full" lang="en">
      <head>
        <meta charSet="utf-8" />
        <base target="_blank"></base>
        <Meta />
        <Links />
      </head>
      <body className="flex h-full flex-col overflow-hidden bg-surface-sunken">
        {theme && <style>{theme}</style>}
        <CustomisationContext.Provider value={customisations}>
          <TooltipProvider delayDuration={400}>
            <ChatHeader>
              <h1
                className={cn(
                  'font-heading-h2',
                  'text-nowrap',
                  'overflow-hidden',
                  'text-ellipsis',
                  customisations.theme?.brandAccentColor && 'text-inverse',
                )}
              >
                {customisations.title || 'Lorikeet AI Agent'}
              </h1>
              <div className="flex">
                <ButtonIcon
                  className={cn(
                    customisations.theme?.brandAccentColor && 'text-inverse',
                  )}
                  icon={MessageCirclePlusIcon}
                  label="Start new chat"
                  onClick={() => {
                    parent.postMessage(
                      {
                        type: 'startNewChat',
                      },
                      '*',
                    )
                  }}
                  size="large"
                  type="button"
                />
                <ButtonIcon
                  className={cn(
                    customisations.theme?.brandAccentColor && 'text-inverse',
                  )}
                  icon={XIcon}
                  label="Close"
                  onClick={() => parent.postMessage({ type: 'closeChat' }, '*')}
                  size="large"
                  type="button"
                />
              </div>
            </ChatHeader>
            <Outlet />
          </TooltipProvider>
        </CustomisationContext.Provider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)

const CustomBoundary = () => {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    return (
      <html className="rebrand" lang="en">
        <head>
          <title>Page Not Found</title>
          <Meta />
          <Links />
        </head>
        <body className="bg-surface-sunken">
          <div className="flex min-h-screen flex-col items-center justify-center py-2">
            <h1 className="font-bold font-heading-h2">{error.status}</h1>
            <h2 className="font-bold font-heading-h3">{error.statusText}</h2>
            <p className="font-text-m">
              {error.status === 400 ? error.data : 'Page not found'}
            </p>
          </div>
          <Scripts />
        </body>
      </html>
    )
  }

  return (
    <html className="rebrand" lang="en">
      <head>
        <title>Server Error</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-surface-sunken">
        <div className="flex min-h-screen flex-col items-center justify-center py-2">
          <h1 className="font-bold font-heading-h2">Unexpected error</h1>
          <p className="font-text-m">
            The team have been notified. Please refresh and try again.
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = CustomBoundary
